<template>
  <page-view left-arrow title="个人中心" white-bg>
    <divider-box size="5" />
    <van-cell-group :border="false">
      <van-cell title="绑定手机号" :value="yhsj" is-link size="large" to="/bind-mobile">
        <template #icon>
          <van-icon :name="require('@/assets/icons/mobile-link.png')" size="28" :style="{marginRight: '10px'}"/>
        </template>
      </van-cell>
      <van-cell title="户号绑定" is-link size="large" to="/yhbh">
        <template #icon>
          <van-icon :name="require('@/assets/icons/user-link.png')" size="28" :style="{marginRight: '10px'}"/>
        </template>
      </van-cell>
    </van-cell-group>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'My',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters(['yhsj'])
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
  .van-cell.van-cell--large,.van-cell__left-icon, .van-cell__right-icon{
    line-height: 32px;
  }
</style>